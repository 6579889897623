// this is temporary for tax_rate transition from 7.70 to 8.10 from 1-1-2024
const TRANSACTION_DOCTYPES = [
  "Sales Invoice",
  "Sales Order",
  "Quotation",
  "Delivery Note",
  "Purchase Invoice",
  "Purchase Order",
  "Purchase Receipt",
];

const SALES_DOCTYPES = [
  "Sales Invoice",
  "Sales Order",
  "Quotation",
  "Delivery Note",
];

const TAX_ACCOUNT_NUMBERS = ["2200", "1170"];
const OLD_TAX_RATE = 7.7;
const NEW_TAX_RATE = 8.1;
const VALID_RATES = [OLD_TAX_RATE, NEW_TAX_RATE];

erpnext.TransactionController = class CustomTransactionController extends (
  erpnext.TransactionController
) {
  taxes_and_charges() {
    var me = this;
    if (this.frm.doc.taxes_and_charges) {
      return this.frm.call({
        method: "erpnext.controllers.accounts_controller.get_taxes_and_charges",
        args: {
          master_doctype: frappe.meta.get_docfield(
            this.frm.doc.doctype,
            "taxes_and_charges",
            this.frm.doc.name
          ).options,
          master_name: this.frm.doc.taxes_and_charges,
        },
        callback: function (r) {
          if (!r.exc) {
            // updating tax_rate as per date
            const tax_rate = get_tax_rate(
              me.frm.doc.posting_date || me.frm.doc.transaction_date
            );
            const description = `MWST ${tax_rate}%`;

            r.message.forEach((row) => {
              if (is_rate_change_required(row, tax_rate)) {
                row.rate = tax_rate;
                row.description = description;
              }
            });

            if (me.frm.doc.shipping_rule && me.frm.doc.taxes) {
              for (let tax of r.message) {
                me.frm.add_child("taxes", tax);
              }

              refresh_field("taxes");
            } else {
              me.frm.set_value("taxes", r.message);
              me.calculate_taxes_and_totals();
            }
          }
        },
      });
    }
  }
};

frappe.provide("erpnext.taxes_and_totals");
$.extend(
  erpnext.taxes_and_totals,
  new erpnext.TransactionController({ frm: cur_frm })
);

TRANSACTION_DOCTYPES.forEach((doctype) => {
  frappe.ui.form.on(doctype, {
    posting_date: validate_tax_rate,
    transaction_date: validate_tax_rate,
  });
});

function validate_tax_rate(frm) {
  if (!(frm.doc.taxes || frm.doc.taxes.length > 0)) return;

  const tax_rate = get_tax_rate(
    frm.doc.posting_date || frm.doc.transaction_date
  );
  const description = `MWST ${tax_rate}%`;
  let modified = false;

  frm.doc.taxes.forEach((row) => {
    if (is_rate_change_required(row, tax_rate)) {
      row.rate = tax_rate;
      row.description = description;
      modified = true;
    }
  });

  if (modified) {
    frm.cscript.calculate_taxes_and_totals();
  }
}

function is_rate_change_required(row, tax_rate) {
  const account_number = row.account_head.split(" ")[0];
  return (
    row.charge_type !== "Actual" &&
    TAX_ACCOUNT_NUMBERS.includes(account_number) &&
    VALID_RATES.includes(row.rate) &&
    row.rate !== tax_rate
  );
}

function get_tax_rate(date) {
  return new Date(date) >= new Date("2024-01-01") ? NEW_TAX_RATE : OLD_TAX_RATE;
}

// customer specific default
SALES_DOCTYPES.forEach((doctype) => {
  frappe.ui.form.on(doctype, {
    customer: async function (frm) {
      if (frm.doc.customer === "C101466") {
        await frm.set_value("taxes_and_charges", "MWST (enthalten) - OT");
        await frm.set_value("shipping_rule", "Versandspesen (Manor)");
      }
    },
  });
});

async function validate_tax_amount_inclusion_in_print_rate(frm) {
  if (!frm.doc.customer_group) {
    return;
  }

  const { message } = await frappe.db.get_value(
    "Customer Group",
    frm.doc.customer_group,
    "dont_include_tax_amount"
  );
  if (message && !message.dont_include_tax_amount) {
    return;
  }

  frm.doc.taxes.forEach((tax_row) => {
    if (tax_row.included_in_print_rate) {
      frappe.throw(
        __(
          `Customer Group ${frm.doc.customer_group} does not allow inclusive tax, but Tax ${tax_row.account_head} is included in the print rate.`
        )
      );
    }
  });
}

frappe.ui.form.on("Sales Taxes and Charges", {
  included_in_print_rate: validate_tax_amount_inclusion_in_print_rate,
});

["Sales Invoice", "Sales Order"].forEach((doctype) => {
  frappe.ui.form.on(doctype, {
    customer_group: validate_tax_amount_inclusion_in_print_rate,
  });
});
